import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../Header/Header";
import "../../styles/style.css";
import HeaderFunctions from "../HeaderFunctions/HeaderFunctions";
import Sidebar from "./sidebar";

const CreateDocument = () => {
  const navigate = useNavigate();
  const {
    loading,
    loadingoverview,
    loadingig,
    loadingusermaser,
    loadingcustomerlist,
    loadingprojectlist,
    loadingrolelist,
    handleLogout,
    handleOverviewClick,
    handleIGClick,
    handleUserMaster,
    handleCustomerList,
    handleProjectList,
    handleRoleList,
  } = HeaderFunctions();

  const [formData, setFormData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({});
  const [requirement, setRequirement] = useState("");

  // const modules = {
  //   toolbar: [
  //     [{ size: ["small", false, "large", "huge"] }],
  //     ["bold", "italic", "underline", "strike", "blockquote"],
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     ["link", "image"],
  //     [
  //       { list: "ordered" },
  //       { list: "bullet" },
  //       { indent: "-1" },
  //       { indent: "+1" },
  //       { align: [] },
  //     ],
  //     [
  //       {
  //         color: [
  //           "#000000",
  //           "#e60000",
  //           "#ff9900",
  //           "#ffff00",
  //           "#008a00",
  //           "#0066cc",
  //           "#9933ff",
  //           "#ffffff",
  //           "#facccc",
  //           "#ffebcc",
  //           "#ffffcc",
  //           "#cce8cc",
  //           "#cce0f5",
  //           "#ebd6ff",
  //           "#bbbbbb",
  //           "#f06666",
  //           "#ffc266",
  //           "#ffff66",
  //           "#66b966",
  //           "#66a3e0",
  //           "#c285ff",
  //           "#888888",
  //           "#a10000",
  //           "#b26b00",
  //           "#b2b200",
  //           "#006100",
  //           "#0047b2",
  //           "#6b24b2",
  //           "#444444",
  //           "#5c0000",
  //           "#663d00",
  //           "#666600",
  //           "#003700",
  //           "#002966",
  //           "#3d1466",
  //           "custom-color",
  //         ],
  //       },
  //     ],
  //   ],
  // };

  // const formats = [
  //   "header",
  //   "height",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "strike",
  //   "blockquote",
  //   "list",
  //   "color",
  //   "bullet",
  //   "indent",
  //   "link",
  //   "image",
  //   "align",
  //   "size",
  // ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };
  // const handleProcedureContentChange = (content) => {
  //   setRequirement(content);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const token = localStorage.getItem("token");
        const requestData = {
          name: formData.name,
          requirement: requirement,
        };
        await axios.post(
          "https://ifhirehealth.clindcast.com/api/storedocument",
          requestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setFormData({
          name: "",
        });
        setRequirement("");
        navigate("/document-list");
      } catch (error) {
        console.error("Failed to store data:", error.message);
      }
    } else {
      setErrors(validationErrors);
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.name || !formData.name.trim()) {
      errors.name = "Name is required";
    }
    return errors;
  };
  const handleCancel = () => {
    setFormData({
      name: "",
    });
    setRequirement("");
  };
  return (
    <>
      <Header
        loading={loading}
        loadingoverview={loadingoverview}
        loadingig={loadingig}
        loadingusermaser={loadingusermaser}
        loadingcustomerlist={loadingcustomerlist}
        loadingprojectlist={loadingprojectlist}
        loadingrolelist={loadingrolelist}
        handleLogout={handleLogout}
        handleOverviewClick={handleOverviewClick}
        handleIGClick={handleIGClick}
        handleUserMaster={handleUserMaster}
        handleCustomerList={handleCustomerList}
        handleProjectList={handleProjectList}
        handleRoleList={handleRoleList}
      />

      <div className="container">
        <nav style={{ "--bs-breadcrumb-divider": ">" }} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="#"></Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#"></Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"></li>
          </ol>
        </nav>
      </div>

      <div className="container pb-4">
        <div className="box-bdr-dash">
          <div className="row">
            <Sidebar />
            <div className="col-md-9">
              <div className="box mb-2 form-box">
                <div className="box-top-title fhir-title">
                  <span className="pd-overview-count">Add Document</span>
                </div>
                <div className="ig-forms">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            Name <span className="text-danger fs-6">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name && "is-invalid"
                            }`}
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            aria-describedby="text"
                            placeholder="Name"
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="requirement" className="form-label">
                            Description
                          </label>
                          {/* <ReactQuill
                            name= "requirement"
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder="Write your content ..."
                            onChange={handleProcedureContentChange}
                            className="builder"
                          /> */}
                          <iframe
                            src="https://ifhirehealth.clindcast.com/create_fhirdocument"
                            title="Document Preview"
                            style={{ width: "100%", height: "800px" }}
                            placeholder="Write your content ..."
                            // onChange={handleProcedureContentChange}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <div className="form-buttons mtop45">
                      <button
                        type="submit"
                        className="btn btn-primary submit-btn"
                      >
                        <i className="fa fa-check" /> Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary cancel-btn"
                        onClick={handleCancel}
                      >
                        <i className="fa fa-close" /> Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDocument;
